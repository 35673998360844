<template>
  <basic-modal @open="openModal" width="900" transition="slide-x-transition">
    <template #header>
      <divider
        title="crop for pdf"
        sub-title="make it precise"
        size="small"
      ></divider>
    </template>
    <template #default>
      <div class="px-6 px-md-14 mt-8 mb-11">
        <div class="">
          <image-cropper
            :stencilProps="{ aspectRatio: 540 / 630 , checkImageOrigin: false }"
            :url.sync="fileForm.thumbnail"
            v-model="fileForm"
            label="image"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-7 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel btn__size__18"
          text="cancel"
          @click.native="$_closeModal()"
          height="45"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign site__button width-200 ml-5 btn__size__16"
          text="save"
          @click.native="save"
          :loading="loadingSendRequest"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import FileRepository from "@/abstraction/repository/FileRepository";
const fileRepository = new FileRepository();
import ImageCropper from "@/components/Global/Input/ImageCropper";
import { makeid } from "@/utils/math";
const RANDOM_TOKEN = makeid(50);
export default {
  name: "modal_crop_gallery_image_for_pdf",
  components: {
    ImageCropper,
  },
  data: () => ({
    fileForm: {
      thumbnail: "https://via.placeholder.com/1000",
      collection_name : "pdf_images",
      url: null,
      model_id : null,
      model_name : "model",
      batch_id : RANDOM_TOKEN,
    },
    loadingSendRequest : false,
  }),
  methods: {
    openModal({model}){
      if(model){
        const image = {...model};
        this.fileForm.model_id = image.modelId;
        this.fileForm.thumbnail = image.image
        this.fileForm.url = image.image
      } 
    },
    async save(){
      try {
        this.loadingSendRequest = true;
        const response = await fileRepository.store(this.fileForm);
        this.$emit("success", response);
        this.$_closeModal();
      } catch (error) {
        return error;
      }finally{
        this.loadingSendRequest = false;
      }
    }
  }
};
</script>
<style scoped>
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.btn-more {
  font-family: "Barlow-EL", sans-serif;
  padding: 13px 10px !important;
}
.request__details__information::before {
  height: 24px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 2px 0 0;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 12px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.request__details__information--sub-title {
  font-size: 15px;
  font-family: "Montserrat";
  color: var(--color-black);
  position: relative;
  top: -0.3rem;
}
.category__chip {
  font-size: 14px;
  font-family: "Montserrat-light";
}
</style>
